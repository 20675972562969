// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import Link from '../link'
import '../link/style.less'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const { Fragment } = React

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
const DesktopHeader = React.memo(() => (
  <Fragment>
    <ul className="as-h3">
      <li>
        <Link to="/">dmi</Link>
      </li>
      <li>::</li>
      <li>
        <Link to="/#publishing">publishing</Link>
      </li>
      <li>+</li>
      <li>
        <Link to="/#accessibility">accessibility</Link>
      </li>
      <li>+</li>
      <li>
        <Link to="/#education-technology">education</Link>
      </li>
      <li>+</li>
      <li>
        <Link>social venture</Link>
      </li>
    </ul>
    <ul className="as-h3">
      <li>
        <Link to="/#who-we-are">who</Link>
      </li>
      <li>//</li>
      <li>
        <Link to="/#what-we-have-done-so-far">what</Link>
      </li>
      <li>//</li>
      <li>
        <Link to="/#technology-but-why">why</Link>
      </li>
    </ul>
  </Fragment>
))

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default DesktopHeader
