// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import Row from 'antd/lib/row'
import 'antd/lib/row/style/css'

import Col from 'antd/lib/col'
import 'antd/lib/col/style/css'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import Link from '../link'
import '../link/style.less'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
// const { Fragment } = React

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Footer */
const Footer = () => (
  <footer>
    <div className="container prelude">
      <h1>Digital Media Initiatives</h1>
      <Row
        gutter={[
          { xs: 0, sm: 0, md: 0, lg: 0, xl: 0, xxl: 0 },
          { xs: 0, sm: 0, md: 0, lg: 0, xl: 0, xxl: 0 },
        ]}
        justify="space-between"
      >
        <Col xs={24} sm={24} md={13} lg={13} xl={13} xxl={13}>
          <h2>Company</h2>
          <p>
            Digital Media Initiatives is a small and good-tech. project from
            India. For the last two decades we have worked in the domains of
            traditional print publishing, digital publishing and accessible
            publishing. Recently we have ventured into education technology and
            supporting social projects.
          </p>
          <h2>Legal</h2>
          <p>
            DMI Systems Private Limited (DMI Systems) is a freshly incorporated
            corporation (incorporated on October 25th, 2021). DMI Systems is
            built with the technology transferred by Digital Media Initiatives,
            which was incorporated as a proprietorship firm in 2007, and would
            be fully acquired by DMI Systems by 31st March 2022.
          </p>
          <h2>License</h2>
          <p>
            DMI Systems is in the business of providing services (non-trading
            ITeS), such as Information technology (IT) consulting and support
            services, design, and development services (SAC Code 998313, 998314,
            998315), and Other information technology services (SAC Code
            998319), such as Data processing, Educational support services, and
            Providing software support and maintenance to the clients.
          </p>
          <h2>Registered Address</h2>
          <p>
            DMI Systems Pvt. Ltd.
            <br />
            2nd Floor, 2/330/408, Vishnupuri,
            <br />
            Aligarh 202001 IN-UP
          </p>
          <h2>Identifiers</h2>
          <p>
            CIN: U80301UP2021PTC154466
            <br />
            GSTIN: 09AAICD8471H1Z8
          </p>
        </Col>
        <Col xs={0} sm={0} md={7} lg={7} xl={7} xxl={7}>
          <h2>Publishing</h2>
          <p>
            Publishing Services
            <br />
            Digital Rights Management
            <br />
            Platform for Indie Publishers
          </p>
          <h2>Accesibility</h2>
          <p>
            NIMAS Conversion
            <br />
            Document Remediation
            <br />
            Braille Books
          </p>
          <h2>Education</h2>
          <p>
            Curriculum Publishing
            <br />
            Digital Learning Materials
            <br />
            Competency Based Learning Platform
          </p>
          <h2>About</h2>
          <p>
            Who we are
            <br />
            What we have done so far
            <br />
            Technology, but why?
          </p>
          <h2>Contact</h2>
          <p>
            hello@dmi.systems
            <br />
            Enquiry Form
          </p>
        </Col>
      </Row>
    </div>
    <div className="container end-notice">
      <p>
        "The past is our foundation, the present is our material and the future
        is our aim and summit."
        <br />~ Sri Aurobindo
      </p>
    </div>
    <div className="container copyright">
      <p>© 2007–2022 Digital Media Initiatives</p>
    </div>
  </footer>
)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default Footer
