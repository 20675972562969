// ------------------------------------------------------------------------------
// ---------------------------------------------------------------------- Imports
// ------------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React, { useEffect } from 'react'
import classnames from 'classnames'

import isNumber from 'lodash/isNumber'
import min from 'lodash/min'
import max from 'lodash/max'

import { scaleLinear } from 'd3-scale'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import { useMediaQuery } from 'react-responsive'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
// const { Fragment } = React
const steps = [
  200, 400, 600, 800, 1000, 1200, 1400, 1600, 1800, 2000, 2200, 2400, 2600,
]

const range = [15, 25]

const scale = scaleLinear()
  .domain([min(steps), max(steps)])
  .range([min(range), max(range)])
  .clamp(true)

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Methods
// ----------------------------------------------------------------------------
/** [description] */
const closestIndex = (arr, target) => {
  var i = 0,
    j = arr.length - 1,
    k

  while (i <= j) {
    k = Math.floor((i + j) / 2)
    if (target === arr[k] || Math.abs(i - j) <= 1) {
      return k
    } else if (target < arr[k]) {
      j = k - 1
    } else {
      i = k + 1
    }
  }
  return -1
}

/** [description] */
const getClosest = (arr, target) => {
  const closest = closestIndex(arr, target)
  const result = [arr[closest]]

  if (isNumber(arr[closest - 1]) && isNumber(arr[closest + 1])) {
    if (
      Math.abs(target - arr[closest - 1]) < Math.abs(target - arr[closest + 1])
    ) {
      result.push(arr[closest - 1])
    } else {
      result.push(arr[closest + 1])
    }
  }

  return result
}

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** [description] */
const MediaState = ({
  children,
  className: givenClassName = '',
  mediaState,
  updateMediaState,
  client,
  screenSizeState = undefined,
  updateScreenSizeState = undefined,
  ...otherProps
}) => {
  const fx = useMediaQuery

  // Defaults
  let screenWidth = 1440
  let screenHeight = 900
  let screenOrientation = 'landscape'

  // Widths
  const widthIsExtraExtraLarge = fx({ minWidth: 1600 })
  const widthIsExtraLarge = fx({ minWidth: 1201, maxWidth: 1599 })
  const widthIsLarge = fx({ minWidth: 993, maxWidth: 1200 })
  const widthIsMedium = fx({ minWidth: 769, maxWidth: 992 })
  const widthIsSmall = fx({ minWidth: 577, maxWidth: 768 })
  const widthIsExtraSmall = fx({ maxWidth: 576 })

  // Heights
  const heightIsExtraExtraLarge = fx({ minHeight: 1600 })
  const heightIsExtraLarge = fx({ minHeight: 1201, maxHeight: 1599 })
  const heightIsLarge = fx({ minHeight: 993, maxHeight: 1200 })
  const heightIsMedium = fx({ minHeight: 769, maxHeight: 992 })
  const heightIsSmall = fx({ minHeight: 577, maxHeight: 768 })
  const heightIsExtraSmall = fx({ maxHeight: 576 })

  // Orientation
  const isPortrait = fx({ orientation: 'portrait' })
  const isLandscape = fx({ orientation: 'landscape' })
  screenOrientation = isPortrait === true ? 'portrait' : 'landscape'

  // Screen type
  const isRetina = fx({ minResolution: '2dppx' })

  // Get point
  const neighbours = getClosest(steps, screenWidth)
  const smallerNeighbour = max(neighbours)
  const point = scale(smallerNeighbour)

  // Classname
  const className = classnames(
    {
      'is-media-aware': true,
      'w-xxl': widthIsExtraExtraLarge,
      'w-xl': widthIsExtraLarge,
      'w-lg': widthIsLarge,
      'w-md': widthIsMedium,
      'w-sm': widthIsSmall,
      'w-xs': widthIsExtraSmall,
      'h-xxl': heightIsExtraExtraLarge,
      'h-xl': heightIsExtraLarge,
      'h-lg': heightIsLarge,
      'h-md': heightIsMedium,
      'h-sm': heightIsSmall,
      'h-xs': heightIsExtraSmall,
      'is-portrait': isPortrait,
      'is-landscape': isLandscape,
      'is-retina': isRetina,
      'is-client': client,
    },
    givenClassName
  )

  useEffect(() => {
    if (typeof window !== 'undefined') {
      ;({ innerWidth: screenWidth, innerHeight: screenHeight } = window)
    }

    // Get point
    const newNeighbours = getClosest(steps, screenWidth)
    const newSmallerNeighbour = max(newNeighbours)
    const newPoint = scale(newSmallerNeighbour)

    const newMediaState = {
      widthIsExtraExtraLarge,
      widthIsExtraLarge,
      widthIsLarge,
      widthIsMedium,
      widthIsSmall,
      widthIsExtraSmall,
      heightIsExtraExtraLarge,
      heightIsExtraLarge,
      heightIsLarge,
      heightIsMedium,
      heightIsSmall,
      heightIsExtraSmall,
      isPortrait,
      isLandscape,
      isRetina,
      currentOrientation: isPortrait === true ? 'portrait' : 'landscape',
      currentWidth:
        widthIsExtraExtraLarge === true
          ? 'w-xxl'
          : widthIsExtraLarge === true
          ? 'w-xl'
          : widthIsLarge === true
          ? 'w-lg'
          : widthIsMedium === true
          ? 'w-md'
          : widthIsSmall === true
          ? 'w-sm'
          : 'w-xs',
      currentHeight:
        heightIsExtraExtraLarge === true
          ? 'h-xxl'
          : heightIsExtraLarge === true
          ? 'h-xl'
          : heightIsLarge === true
          ? 'h-lg'
          : heightIsMedium === true
          ? 'h-md'
          : heightIsSmall === true
          ? 'h-sm'
          : 'h-xs',
      screenWidth,
      screenHeight,
      point: newPoint,
    }
    updateMediaState({ ...mediaState, ...newMediaState })
  }, [
    widthIsExtraExtraLarge,
    widthIsExtraLarge,
    widthIsLarge,
    widthIsMedium,
    widthIsSmall,
    widthIsExtraSmall,
    heightIsExtraExtraLarge,
    heightIsExtraLarge,
    heightIsLarge,
    heightIsMedium,
    heightIsSmall,
    heightIsExtraSmall,
    isPortrait,
    isLandscape,
    isRetina,
    screenWidth,
    screenHeight,
    point,
  ])

  return (
    <div className={className} {...otherProps}>
      {children}
    </div>
  )
}

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Exports
// ----------------------------------------------------------------------------
export default MediaState
